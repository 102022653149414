<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 재해기간 -->
          <c-datepicker
            :range="true"
            name="period"
            defaultStart="-6d"
            defaultEnd="0d"
            label="재해기간"
            v-model="searchParam.period"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="사고 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="iimAccidentId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "process-accident",
  data() {
    return {
      grid: {
        columns: [
          {
            fix: true,
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            fix: true,
            name: "accidentTypeName",
            field: "accidentTypeName",
            style: 'width:100px',
            label: "재해유형",
            align: "center",
            sortable: true,
          },
          {
            fix: true,
            name: "accidentName",
            field: "accidentName",
            label: "재해명",
            style: 'width:200px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "occurrenceDate",
            field: "occurrenceDate",
            style: 'width:130px',
            label: "재해일시",
            align: "center",
            sortable: true,
          },
          {
            name: "occurrenceLocation",
            field: "occurrenceLocation",
            style: 'width:130px',
            label: "재해장소",
            align: "left",
            sortable: true,
          },
          {
            name: "occurrenceDeptName",
            field: "occurrenceDeptName",
            // 발생부서
            label: "LBL0002302",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentStatusName",
            field: "accidentStatusName",
            style: 'width:100px',
            // 진행상태
            label: "진행상태",
            align: "center",
            sortable: true,
          },
          {
            name: "accidentUser",
            field: "accidentUser",
            label: '사고자 정보',
            child: [
              {
                name: "accidentDeptName",
                field: "accidentDeptName",
                style: 'width:80px',
                label: "부서",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentUserName",
                field: "accidentUserName",
                style: 'width:80px',
                label: "이름",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentSpotName",
                field: "accidentSpotName",
                style: 'width:80px',
                label: "직위",
                align: "center",
                sortable: true,
              },
            ]
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        occurrenceDeptCd: '',
        accidentStatusCd: null,
        accidentTypeCd: null,
        accidentUserId: '',
        processCd: '',
        period: [],
        mainFlag: 'Y'
      },
      editable: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.iim.accident.process.list.url;
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "사고 상세"; // 사고 상세
      this.popupOptions.param = {
        iimAccidentId: row ? row.iimAccidentId : '',
        stepCd: row ? row.accidentStatusCd : '',
      };
      this.popupOptions.target = () =>
        import(`${"./processAccidentDetail.vue"}`);
      this.popupOptions.width = "80%"
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (type === 'REMOVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
  },
};
</script>